<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      :loading="loading"
    >
      <v-toolbar
        dense
        color="transparent"
      >
        <v-btn
          icon
          @click="$router.push({name: 'suppliers'})"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="viewRow"
          v-permission="['100003']"
          color="primary"
          class="mx-2"
          small
          @click="editClick"
        >
          编辑
        </v-btn>
        <v-btn
          outlined
          small
          class="mx-2"
          @click="$router.push({name: 'suppliers'})"
        >
          取消
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 85vh"
      >
        <v-form
          ref="supplierFormRef"
          :disabled="viewRow"
        >
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 基本信息 ---
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.name"
                :counter="20"
                :rules="[rules.required, rules.counter]"
                label="供应商名称"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.username"
                :counter="20"
                :rules="[rules.required, rules.counter]"
                label="联系人"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.mobile"
                :counter="11"
                :rules="[rules.required, rules.mobile]"
                label="手机号"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="row.is_active"
                :items="supplierStatus"
                :rules="[rules.required]"
                required
                outlined
                dense
                label="状态"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="row.province"
                item-text="name"
                item-value="code"
                :items="provincesList"
                :loading="provinceLoading"
                :rules="[rules.required]"
                :no-data-text="provinceLoading ? '加载中......' : '暂无数据'"
                label="省/直辖市"
                outlined
                dense
                @click="getProvinces"
                @change="changeProvinces"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="row.city"
                item-text="name"
                item-value="code"
                :items="citiesList"
                :loading="cityLoading"
                :rules="[rules.required]"
                :no-data-text="!row.province ? '请先选择省份' : cityLoading ? '加载中......' : '暂无数据'"
                label="城市"
                outlined
                dense
                @click="getCities"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="row.county"
                item-text="name"
                item-value="code"
                :items="areasList"
                :loading="areaLoading"
                :rules="[rules.required]"
                :no-data-text="!row.city ? '请先选择城市' : areaLoading ? '加载中......' : '暂无数据'"
                label="区/县"
                outlined
                dense
                @click="getAreas"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.address"
                label="详细地址"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="!changePassword"
              cols="12"
              md="4"
            >
              <v-btn
                text
                color="primary"
                :disabled="viewRow"
                @click="changePassword=true"
              >
                修改密码
              </v-btn>
            </v-col>
            <v-col
              v-else
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.change_password"
                label="新密码"
                type="password"
                :rules="[rules.required, rules.password]"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!viewRow"
            small
            color="success"
            class="mx-2"
            @click="formSubmit"
          >
            保存
          </v-btn>
        </v-card-actions>
        <v-card-subtitle
          class="d-flex justify-center mt-4"
        >
          --- 产品列表 ---
        </v-card-subtitle>
        <v-data-table
          :headers="itemsHeaders"
          :items="items"
          :items-per-page.sync="itemsOptions.pageSize"
          :page.sync="itemsOptions.page"
          :server-items-length="itemTotal"
          :loading="itemsLoading"
          disable-sort
          item-key="id"
          :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
          @update:items-per-page="getItems"
          @update:page="getItems"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              tag="a"
              target="_blank"
              :to="{name:'goodsDetail', query:{id: item.id}}"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:item.is_selected="{ item }">
            <v-simple-checkbox
              v-model="item.is_selected"
              :ripple="false"
              color="primary"
              :disabled="viewRow"
              @input="goodsIsSelectedClick(item)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiDelete,
  mdiArrowLeft,
  mdiMagnify,
  mdiPlus,
} from '@mdi/js'

import {
  getSupplier,
  getSupplierItems,
  updateSupplier,
  updateSupplierItems,
} from '@/api/supplier'
import { getAreas, getCities, getProvinces } from '@/api/city'

export default {
  name: 'PriceListDetail',
  data() {
    return {
      rowId: null,
      viewRow: true,
      loading: false,
      changePassword: false,
      row: {
        name: null,
        province: null,
        city: null,
        county: null,
        address: '',
        is_active: true,
        username: '',
        mobile: '',
        change_password: null,
      },
      supplierStatus: [
        {
          text: '启用',
          value: true,
        },
        {
          text: '禁用',
          value: false,
        },
      ],
      provincesList: [],
      provinceLoading: false,
      citiesList: [],
      cityLoading: false,
      areasList: [],
      areaLoading: false,
      items: [],
      rules: {
        required: value => (!!value || value === 0) || '必填项.',
        counter: value => (!value || value.length <= 20) || '最大20个字符',
        mobile: value => /^1\d{10}$/.test(value) || '无效的手机号',
        password: value => /^(?![^a-zA-Z]+$)(?!\D+$){8,16}/.test(value) || '密码应为8-16位数字字母组合.',
      },
      icons: {
        mdiDelete,
        mdiArrowLeft,
        mdiMagnify,
        mdiPlus,
      },
      itemsHeaders: [
        { text: '产品名称', value: 'name' },
        { text: 'SKU', value: 'sku' },
        { text: '成本价', value: 'cost_price' },
        { text: '售价', value: 'sale_price' },
        { text: '选择', value: 'is_selected' },
      ],
      itemsOptions: {
        search: '',
        page: 1,
        pageSize: 10,
      },
      itemTotal: 0,
      itemsLoading: false,
    }
  },
  created() {
    const { id } = this.$route.params
    if (id) {
      this.rowId = id
      this.getSupplier()
      this.getItems()
    }
  },
  methods: {
    async getProvinces() {
      this.provinceLoading = true
      const { data } = await getProvinces()
      this.provincesList = data
      this.provinceLoading = false
    },
    changeProvinces() {
      this.row.city = null
      this.row.county = null
    },
    async getCities() {
      console.log(this.row)
      if (!this.row.province) {
        return
      }
      this.cityLoading = true
      const { data } = await getCities({ province_code: this.row.province })
      this.citiesList = data
      this.cityLoading = false
    },
    async getAreas() {
      if (!this.row.city) {
        return
      }
      this.areaLoading = true
      const { data } = await getAreas({ city_code: this.row.city })
      this.areasList = data
      this.areaLoading = false
    },
    getSupplier() {
      this.loading = true
      getSupplier(this.rowId).then(response => {
        this.row = response.data
        this.row.change_password = null
        this.getProvinces()
        this.getCities()
        this.getAreas()
        this.loading = false
      })
    },
    getItems() {
      this.itemsLoading = true
      getSupplierItems(this.rowId, this.itemsOptions).then(response => {
        this.items = response.data.results
        this.itemTotal = response.data.count
        this.itemsLoading = false
      })
    },
    goodsIsSelectedClick(item) {
      if (item.is_selected) {
        updateSupplierItems(this.rowId, {
          action: 1,
          goods_id: item.id,
        })
      } else {
        updateSupplierItems(this.rowId, {
          action: 0,
          goods_id: item.id,
        })
      }
    },
    editClick() {
      this.viewRow = false
    },
    formSubmit() {
      if (!this.$refs.supplierFormRef.validate()) return
      // if (this.changePassword) {
      //   this.row.change_password = this.new_password
      // }
      updateSupplier(this.rowId, this.row).then(res => {
        this.$toast.success('价目表更新成功')
        this.$router.go(0)
      })
    },
  },
}
</script>

<style scoped>

</style>
